import Appsignal from "@appsignal/javascript";
import { plugin } from "@appsignal/plugin-window-events"
import { plugin as plugin_network } from "@appsignal/plugin-breadcrumbs-network";
import { plugin as plugin_console } from "@appsignal/plugin-breadcrumbs-console";

const appsignal = new Appsignal({
    key: "12d63092-fefa-4f5d-b985-2e5799361bca",
    ignoreErrors: [/null is not an object \(evaluating 'Object.prototype\.hasOwnProperty\.call\(e,"telephone"\)'\)/,
      /null is not an object \(evaluating 'Object\.prototype\.hasOwnProperty\.call\(o,"telephone"\)'\)/,
        /Invalid call to runtime\.sendMessage\(\)\. Tab not found\./,
      /Can't find variable: _AutofillCallbackHandler/,
      /Queries with only punctuation characters are not allowed in \/suggest/,
      /Query cannot be empty in \/suggest/,
      /No error has been set/,
      /Load Failed/,
      /Load failed/,
      /No event details available\./,
      /Failed to load because no supported source was found\./,
      /Could not resolve bundle with id [A-z]+/,
      /NetworkError when attempting to fetch resource\./,
      /Failed to fetch/,
      /The user aborted a request\./,
      /The operation was aborted\./,
      /AbortError: Fetch is aborted/,
      /Fetch is aborted/,
      /The fetching process for the media resource was aborted by the user agent at the user's request\./,
      /Illegal invocation/
    ]
});

appsignal.use(plugin())
appsignal.use(plugin_network())
appsignal.use(plugin_console())