import CustomEvents from "./customEvents";

const show_car_service = function () {
  const selector = document.querySelector('.read-more')
  if (selector) {
    selector.addEventListener('click', function (e) {
      e.preventDefault()
      const html = JSON.parse(document.querySelector('#show_car_html').value).description
      document.querySelector('.car-detail-header-description-content').innerHTML = html
      e.target.remove()
    })
  }

  const read_more_reviews = document.querySelector('#js-more-reviews')
  if (read_more_reviews) {
    const read_max_reviews = document.querySelector('#js-max-more-reviews')
    read_more_reviews.addEventListener('click', function (e) {
      e.preventDefault()
      document.querySelectorAll('.car-detail-review ').forEach((el) => el.classList.remove('d-none'))
      read_more_reviews.remove()
      if (read_max_reviews) {
        read_max_reviews.classList.remove('d-none')
      }
    })
  }
}

document.addEventListener('ready', function () {
  show_car_service()
})
document.addEventListener('turbo:load', function () {
  show_car_service()
})
document.addEventListener(CustomEvents.EVENTS_KEY['car-reviews-loaded'], () => {
  show_car_service()
})