import intlTelInput from 'intl-tel-input'
import CustomEvents from "./customEvents";
import getCountry from "./int_tel_input_countries";

intTelInputInit()

function intTelInputInit() {
  const input = document.querySelector('#international_phone')
  if (!input) { return }

  let userCountry = getCountry()
  if (userCountry === null) {
    userCountry = 'fr'
  }

  const iti = intlTelInput(input, {
    initialCountry: userCountry,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js',
    preferredCountries: ["fr","re","gp","mq","gf","es","be","CH"]
  })

  const update_international_phone = function () {
    document.querySelector('#user_phone').value = iti.getNumber()
  }
  input.addEventListener('change', function () {
    update_international_phone()
  })
  input.addEventListener('countrychange', function () {
    update_international_phone()
  })
}

document.addEventListener(CustomEvents.EVENTS_KEY['register-form-changed'], () => {
  intTelInputInit()
})

document.addEventListener(CustomEvents.EVENTS_KEY['validation-phone-form-loaded'], () => {
  intTelInputInit()
})