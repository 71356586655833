import AirDatepicker from 'air-datepicker'
import localeFr from 'air-datepicker/locale/fr'

localeFr.daysMin = localeFr.daysShort

let searchBookingStartsOn

const apply_hours_filter = function (value) {
  const bookingStartsAtSelector = document.querySelector('#starts_at')

  const now = new Date(Date.now())
  const today_is_selected =
    flatpickr.formatDate(now, 'd/m/Y') === value
  let previousElementSelected = false

  for (const element of bookingStartsAtSelector.options) {
    const hours_option = element.value
    const hours_option_split = hours_option.split(':')
    const hours = parseInt(hours_option_split[0])
    const minutes = parseInt(hours_option_split[1])
    if (today_is_selected) {
      if (
        hours < now.getHours() ||
        (hours === now.getHours() && minutes <= now.getMinutes())
      ) {
        if (element.selected) {
          previousElementSelected = true
        }
        element.disabled = 'disabled'
        element.removeAttribute('selected')
      } else if (previousElementSelected) {
        element.selected = 'selected'
        break
      }
    } else {
      element.removeAttribute('disabled')
    }
  }
}
const search_calendar_service = function () {
  const jsSearchHeaderBarStart = document.querySelector('#js_search_header_bar_start')
  const jsSearchHeaderBarEnd = document.querySelector('#js_search_header_bar_end')
  if (document.querySelectorAll('.search-datepicker').length) {
    searchBookingStartsOn = document.querySelector(
      '#search_booking_starts_on'
    )
    const plugins = [new rangePlugin({ input: '#search_booking_ends_on' })]


    const disableMobile = document.querySelector('#changeDatesModal') == null
    // Flatpickr setup
    flatpickr('.search-datepicker', {
      minDate: 'today',
      altInput: false,
      dateFormat: 'd/m/Y',
      position: 'below',
      locale: 'fr',
      disableMobile,
      plugins,
      onChange (selectedDates, value, datepicker) {
        if (jsSearchHeaderBarStart) {
          if (datepicker._input.id === 'search_booking_starts_on') {
            jsSearchHeaderBarStart.innerHTML = flatpickr.formatDate(selectedDates[0], 'd/m/Y')
          } else {
            jsSearchHeaderBarEnd.innerHTML = flatpickr.formatDate(selectedDates[0], 'd/m/Y')
          }
        }
      }
    })

    searchBookingStartsOn.addEventListener('change', function () {
      apply_hours_filter(searchBookingStartsOn.value)
    })
    apply_hours_filter(searchBookingStartsOn.value)
  }
  if (document.querySelectorAll('.air-datepicker').length) {
    let dpMin, dpMax, maxDate

    const selectedStartDates = []
    const startValue = document.querySelector('.air-datepicker.start').dataset.value
    if (startValue) {
      selectedStartDates.push(startValue)
    }

    dpMin = new AirDatepicker('.air-datepicker.start', {
      isMobile: true,
      autoClose: true,
      locale: localeFr,
      minDate: new Date(),
      selectedDates: selectedStartDates,
      onSelect ({ date }) {
        if(!date){
          return
        }
        const formattedDate = flatpickr.formatDate(date, 'd/m/Y')
        if (jsSearchHeaderBarStart) {
          jsSearchHeaderBarStart.innerHTML = formattedDate
        }
        apply_hours_filter(formattedDate)
        dpMax.update({
          minDate: date
        })
        if (date > maxDate) {
          dpMax.selectDate(date)
        }
      }
    })

    let endMinDate = new Date();
    if (startValue){
      endMinDate = startValue
    }
    const selectedEndDates = []
    const endValue = document.querySelector('.air-datepicker.end').dataset.value
    if (endValue) {
      selectedEndDates.push(endValue)
    }
    dpMax = new AirDatepicker('.air-datepicker.end', {
      isMobile: true,
      autoClose: true,
      locale: localeFr,
      minDate: endMinDate,
      selectedDates: selectedEndDates,
      onSelect ({ date }) {
        if(!date){
          return
        }
        maxDate = date
        if (jsSearchHeaderBarEnd) {
          jsSearchHeaderBarEnd.innerHTML = flatpickr.formatDate(date, 'd/m/Y')
        }
      }
    })
  }
}

document.addEventListener('ready', function () {
  search_calendar_service()
})

document.addEventListener('turbo:load', function () {
  search_calendar_service()
})
