import { Modal } from 'bootstrap'

const edit_car_service = function () {
  if(document.querySelector("#car_instant_booking") && document.querySelector("#instantBookingModal")){
    document.querySelector("#car_instant_booking").addEventListener("click", function(e){
      e.preventDefault()
      const modal = Modal.getOrCreateInstance('#instantBookingModal')
      modal.show()
    })
  }
}
document.addEventListener('ready', edit_car_service, false)
document.addEventListener('turbo:load', edit_car_service, false)