const bookingRegisterFormInputEvent = function(event){
  const pattern = event.target.getAttribute('pattern')
  if(!pattern){
    return
  }
  const regEx = new RegExp(pattern)

  let symbol = String.fromCharCode(event.keyCode)
  if (!regEx.test(symbol)) {
    event.preventDefault()
  }
}

const bookingRegisterFormLoaded = function(){
  const selector = document.querySelector('#user_zipcode')
  if(!selector){
    return
  }
  selector.addEventListener('keypress', bookingRegisterFormInputEvent)
}

document.addEventListener('ready', bookingRegisterFormLoaded)
document.addEventListener('turbo:load', bookingRegisterFormLoaded)