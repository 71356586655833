import CustomEvents from "./customEvents";

const password_service = function () {
  const pwd = document.querySelector('input[type="password"]')

  if (!pwd) {
    return
  }

  const pwdReveal = pwd.parentElement.querySelector('.reveal')
  if (!pwdReveal) {
    pwd.insertAdjacentHTML('afterend', '<span class="input-group-btn">\n' +
        '            <button class="btn btn-default reveal" type="button"><i class="fas fa-eye"></i></button>\n' +
        '          </span>')
  }

  document.querySelectorAll('.reveal').forEach(function (el) {
    el.addEventListener('click', function () {
      if (pwd.getAttribute('type') === 'password') {
        pwd.setAttribute('type', 'text')
      } else {
        pwd.setAttribute('type', 'password')
      }
    })
  })
}

document.addEventListener("turbo:load", function () {
  password_service();
})
document.addEventListener("ready", function () {
  password_service();
})

document.addEventListener(CustomEvents.EVENTS_KEY['register-form-changed'], () => {
  password_service()
})