import Helpers from './helpers'
const helpers = new Helpers()

function scrollOnClick () {
    const scrollContainers = document.querySelectorAll('.scroll-container')

    const filteredContainers = Array.from(scrollContainers).filter(container =>
      container.querySelector('.scroll-block') !== null
    )

    for (let scrollContainer of filteredContainers) {
        const elementToScroll = scrollContainer.querySelector('.scroll-block');
        const arrowRight = scrollContainer.querySelector('.js-arrow-right');
        const arrowLeft = scrollContainer.querySelector('.js-arrow-left');

        arrowRight.addEventListener('click', function ()
        {scroll({element: elementToScroll, direction: 'right'})})
        arrowLeft.addEventListener('click', function ()
        {scroll({element: elementToScroll, direction: 'left'})})
    }
}

function scroll(data) {
    let value = scrollValue(data.element, data.direction);
    data.element.scrollBy(value)
}

function scrollValue(element, direction) {

    let scrollValue = calculateScrollValue(element)

    if (direction === 'left') {
        scrollValue = -scrollValue;
    }

    return {left: scrollValue, behavior: 'smooth'}
}

function calculateScrollValue (element) {
    const elementStyle = window.getComputedStyle(element)

    function styleValue (style) {
        return helpers.get_style_numeric_value(elementStyle, style)
    }

    const itemGroupWidth = styleValue('width')
    const itemGroupLeftPadding = styleValue('padding-left')
    const itemGroupRightPadding = styleValue("padding-right")
    const itemGroupHorizontalPadding = itemGroupLeftPadding + itemGroupRightPadding
    const contentWidth = itemGroupWidth - itemGroupHorizontalPadding
    const gapBetweenItemGroups = styleValue('gap');

    return contentWidth + gapBetweenItemGroups
}

document.addEventListener('turbo:load', function () {
    scrollOnClick()
})

document.addEventListener('ready', function () {
    scrollOnClick()
})