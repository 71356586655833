import CustomEvents from './customEvents'
const registerFormChangedEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['register-form-changed'])
const registerFormValidateEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['validation-phone-form-loaded'])
const carReviewsLoadedEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['car-reviews-loaded'])
document.addEventListener('turbo:before-stream-render', (event) => {
    const fallbackToDefaultActions = event.detail.render

    event.detail.render = function (streamElement) {
        if (streamElement.action === 'flash_update') {
            window.scrollTo(0, 0)
        } else if (streamElement.action === 'register_form_changed') {
            document.dispatchEvent(registerFormChangedEvent)
        } else if (streamElement.action === 'validation_phone_form_loaded') {
            document.dispatchEvent(registerFormValidateEvent)
        } else if (streamElement.action === 'reviews_changed') {
            document.dispatchEvent(carReviewsLoadedEvent)
        } else {
            fallbackToDefaultActions(streamElement)
        }
    }
})