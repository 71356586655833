import CustomEvents from "./customEvents";

function phone_validation() {
    const noCodeButton = document.querySelector("#js-phone-no-code")
    const codeBlock = document.querySelector("#js-phone-code-block")
    const changePhoneBlock = document.querySelector("#js-change-phone-block")

    if (noCodeButton) {
        noCodeButton.addEventListener("click", () => {
            codeBlock.hidden = true
            changePhoneBlock.hidden = false
        })
    }
}

document.addEventListener("turbo:load", function () {
    phone_validation();
})
document.addEventListener("ready", function () {
    phone_validation();
})

document.addEventListener(CustomEvents.EVENTS_KEY['validation-phone-form-loaded'], () => {
    phone_validation()
})