import CustomEvents from "./customEvents";

function phone_format() {
    const hiddenPhoneInput = document.getElementById('user_phone')
    if (!hiddenPhoneInput) {
        return
    }

    format_replace(hiddenPhoneInput)
    format_replace_listener(hiddenPhoneInput)

    const phoneInput = document.getElementById('international_phone')
    if (!phoneInput) {
        return
    }
    format_replace_listener(phoneInput)
}

function format_replace_listener(input) {
    input.addEventListener('input', function () {
        format_replace(input)
    })
}

function format_replace(hiddenPhoneInput) {
    hiddenPhoneInput.value = hiddenPhoneInput.value.replace(/[^0-9 \+]/g, '')
}
document.addEventListener("turbo:load", function () {
    phone_format()
})
document.addEventListener("ready", function () {
    phone_format()
})

document.addEventListener(CustomEvents.EVENTS_KEY['validation-phone-form-loaded'], () => {
    phone_format()
})

document.addEventListener(CustomEvents.EVENTS_KEY['register-form-changed'], () => {
    phone_format()
})
