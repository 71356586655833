import CustomEvents from "./customEvents";

const customCheckValidity = function () {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_\-+=\[\]{};:'"\\|,.<>\/?~`]{8,32}$/;
  return regex.test(document.querySelector('#user_password').value)
}
'use strict'

function passwordValidityFeedback() {
  // Fetch the form we want to apply custom Bootstrap validation styles to
  const form = document.querySelector('.js-needs-validation')
  if (!form) {
    return
  }
  form.addEventListener('submit',  (event) => {
    const classList = document.querySelector('#js-pwd-feedback').classList
    if (customCheckValidity()) {
      classList.remove('invalid-feedback')
      classList.remove('text-muted')
    } else {
      classList.add('invalid-feedback')
      classList.remove('text-muted')
      event.preventDefault()
      event.stopPropagation()
    }
    form.classList.add('was-validated')
    }, false)
}
window.addEventListener('load', function () {
  passwordValidityFeedback()
}, false)

document.addEventListener(CustomEvents.EVENTS_KEY['register-form-changed'], () => {
  passwordValidityFeedback()
})