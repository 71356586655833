import Helpers from './helpers'
import BookingService from './booking_service'
import RenderPriceService from './render_price_service'

const helpers = new Helpers()

let tos
let bookingsOptions = []
let age_is_ok = true
let licence_is_ok = true

window.EnableSubmit = function () {
  const category = document.querySelector("input[name='category']:checked")?.value
  const form_contains_other_driver = category == "gift" || category == "many_drivers"
  const starts_on = document.querySelector('#booking_starts_on').value
  const ends_on = document.querySelector('#booking_ends_on').value
  let d_licence
  let d_birthday
  if (form_contains_other_driver) {
    age_is_ok = false
    licence_is_ok = false
    var day = document.querySelector('select[name="other_driver_birthday[other_driver_form(3i)]"]').value
    var month = document.querySelector('select[name="other_driver_birthday[other_driver_form(2i)]"]').value
    var year = document.querySelector('select[name="other_driver_birthday[other_driver_form(1i)]"]').value
    d_birthday = moment(year + '-' + month + '-' + day, 'YYYY-M-D')
    var age = moment(starts_on, 'YYYY-M-D').diff(d_birthday, 'years')

    const day_licence = document.querySelector('select[name="other_driver_licence[other_driver_form(3i)]"]').value
    const month_licence = document.querySelector('select[name="other_driver_licence[other_driver_form(2i)]"]').value
    const year_licence = document.querySelector('select[name="other_driver_licence[other_driver_form(1i)]"]').value
    d_licence = moment(year_licence + '-' + month_licence + '-' + day_licence, 'YYYY-M-D')
    var age_licence = moment(ends_on, 'YYYY-M-D').diff(d_licence, 'years')
    var vars_licence = month_licence && year_licence
  }
  const vars = day && month && year

  const sbmt = document.getElementById('submit-button')

  let minimum_licence_age = document.querySelector('#minimum_licence_age_displayed').value
  let minimum_rental_age = parseInt(document.querySelector('#minimum_rental_age_displayed').value)
  if (isNaN(minimum_rental_age)) {
    minimum_rental_age = 28
  }

  if (isNaN(minimum_licence_age)) {
    minimum_licence_age = 3
  }

  const alert_age = document.querySelector('#js-alert-age')
  if (vars) {
    if (age >= minimum_rental_age && age < 85) {
      alert_age.classList.add('d-none')
      age_is_ok = true
    } else {
      age_is_ok = false
      alert_age.classList.remove('d-none')

    }
  }

  const alert_licence = document.querySelector('#js-alert-licence')
  if (vars_licence) {
    if (age_licence >= minimum_licence_age) {
      licence_is_ok = true
      alert_licence.classList.add('d-none')
    } else {
      licence_is_ok = false
      alert_licence.classList.remove('d-none')
      document.querySelector("#js-alert-licence-text").innerText = `Attention le conducteur à moins de ${minimum_licence_age} ans de permis (cela ne respecte pas les CGU de Roadstr).`
    }
  }

  if(vars && vars_licence) {
    if(moment(d_licence).diff(d_birthday, 'years') < 17){
      licence_is_ok = false
      alert_licence.classList.remove('d-none')
      document.querySelector("#js-alert-licence-text").innerText = `Le conducteur avait moins de 17 ans quand il a passé son permis.`
    }
  }

  if (!sbmt) {
    return
  }
  if (tos && !tos.checked) {
    sbmt.classList.add('disable')
    sbmt.disabled = true
  } else {
    sbmt.classList.remove('disable')
    sbmt.disabled = false
  }
}

function uncheckValidation () {
  document.querySelectorAll('input[name="TOS"]').forEach(function (el) {
    el.checked = false
  })
  EnableSubmit()
}

function hideOtherDriverForm () {
  uncheckValidation()
  const otherDriverDiv = document.querySelector('#js-other-driver-div')
  otherDriverDiv.classList.remove('is-visible')
  otherDriverDiv.querySelectorAll('input').forEach(function (item) {
    item.removeAttribute('required')
  })
  otherDriverDiv.querySelectorAll('select').forEach(function (item) {
    item.removeAttribute('required')
  })

  EnableSubmit()
}


let promo = { reduction: 0 }

function deliveryOptionChanged (e) {
  const selector = e.target
  deliveryOptionChangedHandler(selector)
}

function deliveryOptionChangedHandler (selector) {
  addBookingOption(selector, 'delivery')
  bookingsOptions = bookingsOptions.filter(x => (x.type === 'delivery' && x.id === selector.value) || x.type !== 'delivery')
  refreshPriceDetails()
}

function excessRedemptionOptionChanged (e) {
  const selector = e.target
  excessRedemptionOptionChangedHandler(selector)
}

function excessRedemptionOptionChangedHandler (selector) {
  if (selector.checked) {
    setDepositHTML(selector.dataset.deposit)
    addBookingOption(selector, 'excessRedemptionOption')
    bookingsOptions = bookingsOptions.filter(x => (x.type === 'excessRedemptionOption' && x.id === selector.value) || x.type !== 'excessRedemptionOption')
  }
  refreshPriceDetails()
}

function addBookingOption (selector, type) {
  bookingsOptions.push({
    id: selector.value,
    type
  })
}

function washOptionChangedHandler (selector) {
  if (selector.checked) {
    addBookingOption(selector, 'wash')
  } else {
    bookingsOptions = bookingsOptions.filter(x => (x.type !== 'wash'))
  }
  refreshPriceDetails()
}

function washOptionChanged (e) {
  const selector = e.target
  washOptionChangedHandler(selector)
}

function showOtherDriverForm () {
  uncheckValidation()
  const otherDriverDiv = document.querySelector('#js-other-driver-div')
  otherDriverDiv.classList.add('is-visible')
  otherDriverDiv.querySelectorAll('input').forEach(function (item) {
    item.setAttribute('required', 'required')
  })
  otherDriverDiv.querySelectorAll('select').forEach(function (item) {
    item.setAttribute('required', 'required')
  })

  EnableSubmit()
}

const first_message_service = function () {
  const bookings_confirmation_form = document.querySelector('#bookings_confirmation_form')
  if (!bookings_confirmation_form) {
    return
  }

  tos = document.querySelectorAll('input[name="TOS"]')[0]
  if (tos) {
    tos.addEventListener('change', EnableSubmit)
  } else {
      EnableSubmit()
  }

  const div_selector_names = ['select[name="other_driver_birthday[other_driver_form(3i)]"]',
    'select[name="other_driver_birthday[other_driver_form(2i)]"]',
    'select[name="other_driver_birthday[other_driver_form(1i)]"]',
    'select[name="other_driver_licence[other_driver_form(2i)]"]',
    'select[name="other_driver_licence[other_driver_form(1i)]"]'
  ]
  for (const div_selector_name of div_selector_names) {
    const selector = document.querySelector(div_selector_name)
    if (selector) {
      selector.addEventListener('change', EnableSubmit)
    }
  }

  const category_alone = document.querySelector('#js-category_alone')
  const other_driver_div = document.querySelector("#js-other-driver-div")
  if (category_alone) {
    category_alone.addEventListener('change', hideOtherDriverForm)
    document.querySelector('#js-category_many_drivers').addEventListener('click', showOtherDriverForm)
    document.querySelector('#js-category_gift').addEventListener('click', showOtherDriverForm)
  } else if (other_driver_div) {
    showOtherDriverForm()
  }

  const delivery_selector = document.querySelector('#js-booking_delivery_option')
  if(delivery_selector){
    delivery_selector.addEventListener('change', deliveryOptionChanged)
  }
  const selectors = document.querySelectorAll(".js-franchise-radio")
  for (const selector of selectors) {
    selector.addEventListener('change', excessRedemptionOptionChanged)
  }
  const selector = document.querySelector('#wash_option')
  if (selector) {
    selector.addEventListener('change', washOptionChanged)
  }
  const bookingDistanceSelector = document.querySelector('#js-booking_distance_max')
  if (bookingDistanceSelector) {
    bookingDistanceSelector.addEventListener('change', refreshPriceDetails)
  }
  initializeBookingOptions()
  initializePromoForm()
  refreshPriceDetails()
  initializePoppinButton()

  bookings_confirmation_form.addEventListener("submit", (e) => {
    if (!age_is_ok || !licence_is_ok) {
      e.preventDefault()
      other_driver_div.scrollIntoView({ behavior: "smooth" })
    }
  })
}

let debounce

function setDepositHTML (deposit) {
  const depositHTML = document.querySelector("#js-first-message-deposit")
  depositHTML.innerHTML = deposit + "€"
}

function initializePoppinButton() {
  const intermediateExcessSelector = document.querySelector("#excess_redemption_option_1");
  const selectButton = document.querySelector("#js-intermediate-franchise-button")
  if (selectButton) {
    selectButton.addEventListener("click", (e) => {
      intermediateExcessSelector.checked = true
      excessRedemptionOptionChangedHandler(intermediateExcessSelector);
    })
  }
}

function refreshPriceDetails () {


  const render_price = function (response) {

    const service = new RenderPriceService(
      document.querySelector('.js-summary-prices'),
      document.querySelector('.js-recap-prices'),
      document.querySelector('.js-total'),
      'with_coupon_form'
    )

      service.process(response);

  }

  const formData = getFormData();
  const car_id = document.querySelector('#booking_car_id').value

  clearTimeout(debounce)
  // prevent multi-call during load
  debounce = setTimeout(function () {
    const booking_service = new BookingService()
    booking_service.get_detail_booking_price(formData, car_id, render_price)
  }, 300)

}

function getFormData(){
  const formData = new FormData()
  const distanceMaxSelector = document.querySelector('#js-booking_distance_max')
  if (distanceMaxSelector) {
    const distanceMax = distanceMaxSelector.value;
    formData.append('kmInput', distanceMax)
  }
  let delivery_option = bookingsOptions.find((el) => el.type === 'delivery')
  if (delivery_option) {
    formData.append('deliveryOption', delivery_option.id)
  }

  let excess_redemption_option = bookingsOptions.find((el) => el.type === 'excessRedemptionOption')
  if (excess_redemption_option) {
    formData.append('excessRedemptionOption', excess_redemption_option.id)
  }

  let wash_option = bookingsOptions.find((el) => el.type === 'wash')
  if (wash_option) {
    formData.append('washOption', wash_option.id)
  }
  if (promo.code) {
    formData.append('coupon', promo.code)
  }

  const startDate = new Date(helpers.date_safari_fix(document.querySelector('#booking_starts_on').value))
  const endDate = new Date(helpers.date_safari_fix(document.querySelector('#booking_ends_on').value))

  const starts_on = startDate.toJSON()
  const ends_on = endDate.toJSON()

  formData.append('startsOn', starts_on)
  formData.append('endsOn', ends_on)
  return formData
}

function initializePromoForm () {
  const promoCodeButton = document.querySelector('#js-check-coupon')
  if (!promoCodeButton) {
    return
  }

  const callback = function (response) {
    document.querySelector("#js-coupon").value = '';
    if (response.reduction) {
      document.querySelector('#coupon').classList.remove('is-invalid')
      document.querySelector('#couponFeedback').textContent = ''
      promo.reduction = response.reduction
      promo.code = document.querySelector('#coupon').value
      document.querySelector("#js-coupon").value = promo.code
      refreshPriceDetails()
    } else if (response.error) {
      document.querySelector('#coupon').classList.add('is-invalid')
      promo.code = null
      refreshPriceDetails()
      let errorText
      if (response.error == 'NOT_FOUND') {
        errorText = 'Code invalide'
      } else if (response.error == 'MIN_NOT_REACHED') {
        errorText =`Ce code n’est utilisable qu’à partir de ${response.minimum_amount}€`
      } else if (response.error == 'INCOMPATIBLE_DATES') {
        const start = helpers.format_date(response.min_date)
        const end = helpers.format_date(response.max_date)
        errorText = `Ce code n’est utilisable qu’entre le ${start} et le ${end}`
      }
      document.querySelector('#couponFeedback').textContent = errorText;
    }
  }

  const coupon_click_button_callback = function(){
    const booking_service = new BookingService()
    const formData = new FormData()
    const distanceMaxSelector = document.querySelector('#js-booking_distance_max')
    if (distanceMaxSelector) {
      const full_distance = distanceMaxSelector.value
      formData.append('full_distance', full_distance)
    }
    const starts_on = document.querySelector('#booking_starts_on').value
    const ends_on = document.querySelector('#booking_ends_on').value
    const car_id = document.querySelector('#booking_car_id').value
    const coupon = document.querySelector('#coupon').value
    formData.append('startsOn', starts_on)
    formData.append('endsOn', ends_on)
    formData.append('coupon', coupon)

    booking_service.check_coupon(formData, car_id, callback)
  }

  if (document.querySelector('#coupon').value){
    coupon_click_button_callback()
  }


  promoCodeButton.addEventListener('click', function (ev) {

   coupon_click_button_callback();
  })
}

function initializeBookingOptions () {

  const delivery_selector = document.querySelector("#js-booking_delivery_option")
  if(delivery_selector) {
    deliveryOptionChangedHandler(delivery_selector)
  }
  const selector = document.querySelector('#wash_option')
  if (selector) {
    washOptionChangedHandler(selector)
  }

  const insurance_selector = document.querySelector('#insurance_option')
  if (insurance_selector) {
    addBookingOption(insurance_selector)
  }

  const fees_selector = document.querySelector('#fees_option')
  if (fees_selector) {
    addBookingOption(fees_selector)
  }

}

document.addEventListener('ready', function () {
  first_message_service()
})
document.addEventListener('turbo:load', function () {
  first_message_service()
})
